import "./import/modules";

document.addEventListener("DOMContentLoaded", function(event) {
    let windowHeight = document.documentElement.clientHeight;
    let windowWidth = document.documentElement.clientWidth;
    
    function showVisible() {
        let elems = document.querySelectorAll('.animate-visible');
        for (let i = 0; i < elems.length; i++) {
            let elem = elems[i];
            if (isVisible(elem)) {
                elem.classList.remove('animate-visible');
            }
        }
    }
    
    function isVisible(elem) {
        let coords = elem.getBoundingClientRect();
        let topVisible = coords.top >= 0 && coords.top < windowHeight;
        let bottomVisible = coords.bottom < windowHeight && coords.bottom >= 0;
        return topVisible || bottomVisible;
    }
    
    document.addEventListener('scroll', function() {
        showVisible();
    });

    $(document).ready(function() {
        setTimeout(
            function() {
                document.querySelector('.preloader').classList.add('preloader--hidden');
                setTimeout(function() {
                    document.querySelector('.preloader').remove();
                    showVisible();

                    let requestComplete = document.querySelector('.send-requestComplete');
                    let requestMenu = document.querySelector('.send-request__menu');
                    requestComplete.style = '';
                    requestMenu.style = '';
                }, 400);
        }, 1000);
    });
});