'use strict';

document.addEventListener("DOMContentLoaded", function(event) {
    const SendRequest = {
        form: document.querySelector('.form'),
        inputs: document.querySelectorAll('input[type=text],input[type=password],input[type=tel],input[type=email],input[type=time],input[type=date],input[type=url], textarea'),
        inpName: document.querySelector('input[name=name]'),
        inpEmail: document.querySelector('input[name=email]'),
        inpMessage: document.querySelector('textarea[name=message]'),
        button: document.querySelector('.form__submit'),
        focused: document.querySelectorAll('form__label--focused'),
    
        validInput: function(value, inpName) {
            if (inpName == 'validName') {
                const regex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
                return regex.test(value);
            } else if (inpName == 'validEmail') {
                const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
                return regex.test(value);
            }
        },
        validFields: function(event) {
            let validForm = true;
    
            for (let i = 0; i < this.inputs.length; i++) {
                let validFunc = this.inputs[i].dataset.valid;
                
                if (this.inputs[i].value == '') {
                    validForm = false;
                } else if (this.validInput(this.inputs[i].value, validFunc) == false) {
                    validForm = false;
                }
            }
    
            return validForm;
        },
        submit: function() {
            let self = this;
    
            this.form.addEventListener('submit', function(event) {
                event.preventDefault();

                let name = event.target.querySelector('input[name="name"]').value;
                let email = event.target.querySelector('input[name="email"]').value;
                let message = event.target.querySelector('textarea[name="message"]').value;

                if (self.validFields(event)) {
                    let data = { "data": {	"attributes": {	"email": `${email}`, "full_name": `${name}`, "message": `${message}`, "phone": `null` }, "type": "request" } };

                    let req = new XMLHttpRequest();
                    req.open('POST', 'https://api.eurohouse.ca/requests/create', true);
                    req.setRequestHeader('Content-type', 'application/json; charset=utf-8');
                    req.onload = function() {
                        if (req.status == 204) {
                            console.log(req.response);
                        } else {
                            throw new Error(req.statusText);
                        }
                    }

                    req.onerror = function() {
                        throw new Error('Network Error');
                    }

                    req.send(JSON.stringify(data));

                    self.inpName.value = '';
                    self.inpEmail.value = '';
                    self.inpMessage.value = '';
                    let validWrap = document.querySelectorAll('.form__wrap');
                    
                    let completed = document.querySelector('.send-requestComplete');
                    let menu = document.querySelector('.send-request__menu');

                    menu.classList.remove('send-request__menu--open');
                    
                    setTimeout(function() {
                        completed.classList.add('send-requestComplete--visible');
                    }, 500);

                    
                    for (let i = 0; i < validWrap.length; i++) {
                        validWrap[i].classList.remove('form__wrap--valid');
                    }
                }
                
                return false;
            });
        },
        inpInit: function() {
            let self = this;
    
            for (let i = 0; i < this.inputs.length; i++) {
    
                this.inputs[i].addEventListener('focus', function(event) {
                    let label = event.target.parentElement.parentElement.querySelector('label');
                    label.classList.add('form__label--focused');
                });
            }
        },
        init: function() {
            this.inpInit();
            this.submit();
        }
    }
    
    SendRequest.init();
});
