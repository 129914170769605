'use strict';

document.addEventListener("DOMContentLoaded", function(event) {
    const SendRequest = {
        menu: document.querySelector('.send-request__menu'),
        wrapper: document.querySelector('.send-request__wrapper'),
        buttons: document.querySelectorAll('.requestBtn'),
        completedMenu: document.querySelector('.send-requestComplete'),
        completedBtn: document.querySelector('.closeMenu'),
        state: false,
        
        clearForm: function() {
            let labels = document.querySelectorAll('.form__label');
            let inputs = document.querySelectorAll('.form__inp');

            for (let i = 0; i < labels.length; i++) {
                labels[i].classList.remove('form__label--focused');
                inputs[i].value = '';
            }
        },
        toggleMenu: function() {
            for (let i = 0; i < this.buttons.length; i++) {
                const self = this;
    
                this.buttons[i].addEventListener('click', function() {
                    self.state = !self.state;
                    self.menu.classList.toggle('send-request__menu--open');
                });
            }


        },
        closeMenu: function() {
            const self = this;
            
            document.body.addEventListener('click', function(event) {
                let stopEvent = false;
    
                if (event.target.closest('.header__menu') || event.target.classList.contains('requestBtn')) {
                    stopEvent = true;

                    self.clearForm();
                }

                if (self.state && !stopEvent && event.target.closest('.send-request') == null) {

                    self.state = !self.state;
                    self.menu.classList.remove('send-request__menu--open');

                    self.clearForm();
                }
            });

            this.completedBtn.addEventListener('click', function() {
                self.completedMenu.classList.remove('send-requestComplete--visible');
            });
        },
        init: function() {
            this.closeMenu();
            this.toggleMenu();
        }
    }
    
    SendRequest.init();
});
