'use strict';

document.addEventListener("DOMContentLoaded", function(event) {
    const MainMenu = {
        menu: document.querySelector('.header__menu'),
        buttons: document.querySelectorAll('.header__menuBtn'),
        state: false,
        
        toggleMenu: function() {
            for (let i = 0; i < this.buttons.length; i++) {
                const self = this;
    
                this.buttons[i].addEventListener('click', function() {
                    self.state = !self.state;
                    self.menu.classList.toggle('header__menu--open');
                });
            }
        },
        closeMenu: function() {
            const self = this;
            document.body.addEventListener('click', function(event) {
                let stopEvent = false;
    
                if (event.target.closest('.header__menuBtn')) {
                    stopEvent = true;
                }
    
                if (event.target.closest('.header__menu') == null && self.state && !stopEvent) {
                    self.state = !self.state;
                    self.menu.classList.remove('header__menu--open');
                }
            });
        },
        init: function() {
            this.closeMenu();
            this.toggleMenu();
        }
    }
    
    MainMenu.init();
});
